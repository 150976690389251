import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SchoolsService {

  constructor() { }

  getSchools(): any  {
    const schools = [
      {id: 1, nombre: 'Desarrollo', descripcion: 'En la escuela de Desarrollo podrás encontrar todos los temas relacionados a programación, estándares de código, metodologías aplicadas al desarrollo de Software y mucho más.', icono: 'assets/logos/PixelingAcademy_Escuela_Desarrollo.svg#Layer_2'},
      {id: 2, nombre: 'Marketing Digital', descripcion: 'En la escuela de Marketing Digital encontrarás desde lo más básico en temas de contenidos digitales, medios y público objetivo, hasta temáticas más profundos para la creación de estrategias de Marketing a nivel profesional', icono: 'assets/logos/PixelingAcademy_Escuela_Marketing.svg#Layer_2'},
      {id: 3, nombre: 'Habilidades Blandas', descripcion: 'Las Habilidades blandas nos permitirán mejorar en aspectos más allá del tema técnico, que podemos aplicar no sólo a nuestra vida profesional, sino personal y familiar.', icono: 'assets/logos/PixelingAcademy_Escuela_HabilidadesBlandas.svg#Layer_2'}
    ];
    return schools;
  }

}
