<div>
    <main>
        <figure>
        <svg class="logo-svg">
            <use xlink:href="../../../assets/logos/PixelingAcademy_Logo.svg#Layer_1"></use>
         </svg>
        </figure>
        <div class="login-container">
            <p>Bienvenido</p>
            <div class="separator-line"></div>
            <div class="form-container">
            <form [formGroup]="formGroup" id="formGroup" (ngSubmit)="onSubmit(formGroup.value)" >

                <div class="row align-items-center">

                    <div class="col-2 remove-padding-right text-align-center"><span><i class="fas fa-lock icon"></i></span></div>
                    <div class="col-10"><mat-form-field class="input-group">
                        <mat-label>Tu correo electrónico</mat-label>
                        <input matInput formControlName="email">
                        <mat-error *ngIf="formGroup.get('email').hasError('email') && !formGroup.get('email').hasError('required')">
                          Ingresa un correo valido
                        </mat-error>
                        <mat-error *ngIf="formGroup.get('email').hasError('required')">
                          El correo es <strong>necesario</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>

                </div>

                <div class="row align-items-center">

                    <div class="col-2 remove-padding-right text-align-center"><span><i class="fas fa-envelope icon"></i></span></div>
                    <div class="col-10">
                        <mat-form-field>
                            <mat-label>Tu contraseña</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
                            <i mat-icon-button class="show-password" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </i>
                            <mat-error *ngIf="formGroup.get('password').hasError('required')">
                                La contraseña es <strong>necesaria</strong>
                              </mat-error>
                          </mat-form-field>

                    </div>

                </div>
              

                  <div class="submit-container">
                    <button mat-flat-button color="primary" [disabled]="formGroup.invalid" type="submit">
                        Ingresar
                    </button>
                </div>

            </form>
        </div>
        </div>
    </main>
</div>
