<header>
    <app-header></app-header>
</header>
<div class="main-container">
    <h1 class="text-align-center bold">¡Bienvenido!</h1>
    <h3 class="text-align-center">Aquí puedes elegir la escuela e ingresar a ver el contenido de cada una de las sesiones organizadas por Cursos.</h3>
    <br>
    <div class="row">
        
        <div class="col-md-12 col-lg-4 margin-bottom-sm" *ngFor="let school of schools">
            <div class="card-container" (click)="selectedCourse(school.id)">
                <figure>
                    <svg class="icon-svg">
                        <use [attr.xlink:href]="school.icono"></use>
                    </svg>
                </figure>
                <h2 class="text-align-center">{{school.nombre}}</h2>
                <br>
                <p class="text-align-center">{{school.descripcion}}</p>
                <span>
                    <a (click)="selectedCourse(school.id)">Ver cursos</a>
                </span>
            </div>
        </div>

    </div>
</div>
