import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './components/login/login.component';
import { DataService } from './services/data.service';
import { SchoolsService } from './services/schools.service';
import { CoursesService } from './services/courses.service';
import { SesionsService } from './services/sesions.service';
import { AuthGuard } from './guards/auth.guard';


import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { MaterialModule } from './material/material.module';
import { FooterComponent } from './shared/footer/footer.component';
import { StudentProfileComponent } from './components/student-profile/student-profile.component';
import { HeaderComponent } from './shared/header/header.component';
import { SchoolsViewComponent } from './components/schools-view/schools-view.component';
import { CoursesComponent } from './components/courses/courses.component';
import { ModalSesionComponent } from './components/modal-sesion/modal-sesion.component';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FooterComponent,
    StudentProfileComponent,
    HeaderComponent,
    SchoolsViewComponent,
    CoursesComponent,
    ModalSesionComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxYoutubePlayerModule.forRoot(),
    HttpClientModule
  ],
  providers: [DataService, SchoolsService, CoursesService, SesionsService, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
