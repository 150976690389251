<div>
    <header class="container-fluid ">
        <a routerLink="/">
            <figure>
                <svg class="logo-svg">
                    <use xlink:href="../../../assets/logos/PixelingAcademy_Logo.svg#Layer_1"></use>
                </svg>
            </figure>
        </a>
        <nav>
            <a routerLink="/escuelas" matTooltip="Escuelas" matTooltipPosition="above" matTooltipClass="tooltip-default">
                <span><i class="fas fa-graduation-cap icon"></i></span>
            </a>
            <a routerLink="/perfil-estudiante" matTooltip="Mi Perfil" matTooltipPosition="above" matTooltipClass="tooltip-default">
                <span><i class="fas fa-user-circle icon"></i></span>
            </a>
            <!--
            <button (click)="logout()" matTooltip="Salir" matTooltipPosition="above" matTooltipClass="tooltip-default">
                <span ><i class="fas fa-sign-out-alt icon" ></i></span>
            </button>
            -->
        </nav>
    </header>
</div>
