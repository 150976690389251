import { Component, OnInit } from '@angular/core';

export interface Historial {
  position: number;
  sesion: string;
  curso: string;
  escuela: string;
}

const ELEMENT_DATA: Historial[] = [
  {position: 1, sesion: 'Nombre sesión', curso: 'Nombre curso', escuela: 'Desarrollo'},
  {position: 2, sesion: 'Nombre sesión', curso: 'Nombre curso', escuela: 'Desarrollo'},
  {position: 3, sesion: 'Nombre sesión', curso: 'Nombre curso', escuela: 'Desarrollo'},
  {position: 4, sesion: 'Nombre sesión', curso: 'Nombre curso', escuela: 'Desarrollo'},
];

@Component({
  selector: 'app-student-profile',
  templateUrl: './student-profile.component.html',
  styleUrls: ['./student-profile.component.css']
})
export class StudentProfileComponent implements OnInit {

  displayedColumns: string[] = ['position', 'sesion', 'curso', 'escuela'];
  dataSource = ELEMENT_DATA;

  constructor() { }

  ngOnInit(): void {
  }


}
