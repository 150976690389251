import { Component, OnInit } from '@angular/core';
import { CoursesService } from '../../services/courses.service';
import { SchoolsService } from '../../services/schools.service';
import { ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalSesionComponent } from '../modal-sesion/modal-sesion.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {
  panelOpenState = false;
  shchoolId: string;
  schools: [];
  nombre: string;
  courses = [];
  loading = true;

  coursesByIdSchoolApi = [];

  constructor(private coursesService: CoursesService,
              private rutaActiva: ActivatedRoute,
              private schoolsService: SchoolsService,
              private router: Router,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getIdSchoolParam();
    this.schools = this.schoolsService.getSchools();
    this.getCourses();
  }

  getCourses(): any {
    this.coursesService.getCoursesByIdSchoolApi(this.shchoolId).subscribe(courses => {
      this.coursesByIdSchoolApi = courses;
      this.loading = false;
    });
  }

  getIdSchoolParam(): any {
    this.rutaActiva.params.subscribe(
      (params: Params) => {
        this.shchoolId = params.id;
      }
    );
  }
  openModal(idSesion: string): void{
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      idSesion
    };

    this.dialog.open(ModalSesionComponent, dialogConfig);

  }

}
