import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {

  constructor(private http: HttpClient) { }

  getCoursesByIdSchoolApi(idSchool: string): any{
    const path = `http://pixacademy.recorvetest.com/api/cursos/${idSchool}`;
    return this.http.get(path);
  }

}
