import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class SesionsService {

  constructor(private http: HttpClient ) {
  }

  getSesion(idSesion: string): any{
    const path = `http://pixacademy.recorvetest.com/api/sesion/${idSesion}`;
    return this.http.get(path);
  }
}
