import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  encapsulation: ViewEncapsulation.None;
  constructor(private _data: DataService, private _router: Router) { }

  ngOnInit(): void {
  }

  logout(): void {
    this._data.logeado = false;
    this._router.navigate(['/']);
  }

}
