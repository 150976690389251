<div *ngIf="loading" class="spinner-container">
    <div class="spinner-border" style="width: 2rem; height: 2rem;" role="status">
    </div>
    <span class="margin-left-sm margin-right-sm semi-bold">Cargando...</span>
  </div>
<div *ngIf="!loading">
    <div *ngFor="let item of currentSesion">
        <header>
            <div class="info-date-time"><!--date  time-->
                <i class="fas fa-calendar-day"></i><span>{{ item.fecha_realizacion }}</span>
                <i class="fas fa-clock"></i><span>{{ item.hora }}</span>
            </div>
            <div class="info-instructor"><!--instructor-->
                <p>Instructor: {{ item.exponente }}</p>
            </div>
        </header>

        <h1 mat-dialog-title class="semi-bold text-align-center course-title">{{ item.titulo }}</h1>

        <mat-dialog-content>
        <p>{{ item.descripcion }}</p>
        <br>
        <div class="video-container">
            <youtube-player
                width="100%"
                height="100%"
                [videoId]="item.URL_video"
                (ready)="savePlayer($event)"
                (change)="onStateChange($event)">
            </youtube-player>
        </div>
        <br>

        <div innerHTML="{{ item.contenido }}"></div>    
        </mat-dialog-content>
    </div>
</div>

