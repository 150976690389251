import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  formGroup: FormGroup;
  hide = true;

  constructor(private _data: DataService, private _router: Router) { }

  ngOnInit(): void {

    this.formGroup = new FormGroup(
    {
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    }
    );
  }

  onSubmit({email, password}): void{
   if ( email === 'pixeling@pixeling.co' && password === '123456'){
    alert('Incio de sesion correcto :)');
    this._data.logeado = true;
    this._router.navigate(['/perfil-estudiante']);
   }else{
    alert('No existe el usuario :(');
    this.clearForm();
   }
  }

  clearForm(): void{
    (document.getElementById('formGroup') as HTMLFormElement).reset();
   }
}
