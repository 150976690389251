import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes, ChildrenOutletContexts } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { StudentProfileComponent } from './components/student-profile/student-profile.component';
import { SchoolsViewComponent } from './components/schools-view/schools-view.component';
import { CoursesComponent } from './components/courses/courses.component';
import { AuthGuard } from './guards/auth.guard';


const routes: Routes = [
  {
    path: '',
    component: SchoolsViewComponent,
  },
  {
    path: 'perfil-estudiante',
    component: StudentProfileComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'escuelas',
    component: SchoolsViewComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'cursos/:id',
    component: CoursesComponent,
    // canActivate: [AuthGuard]
  }
];

@NgModule({
            imports: [
              RouterModule.forRoot(routes)
            ],
            exports: [
              RouterModule
            ],
            declarations: []
          })
export class AppRoutingModule { }
