<header>
    <app-header></app-header>
</header>
<div class="main-container">
    <div *ngFor="let school of schools">
        <div *ngIf="school.id == shchoolId">
                <div class="display-flex align-items-center title">
                    <figure class="figure-svg">
                        <svg class="icon-svg">
                            <use [attr.xlink:href]="school.icono"></use>
                        </svg>
                    </figure>
                    <h1 class="bold text-align-center">{{ school.nombre }}</h1>
                </div>
            <p class="text-align-center description semi-bold">{{ school.descripcion }}</p>
        </div>
    </div>

    <div class="course-container">
        <div>
          <div *ngIf="loading" class="spinner-container">
            <div class="spinner-border text-light" style="width: 2rem; height: 2rem;" role="status">
            </div>
            <span class="text-light margin-left-sm margin-right-sm">Cargando...</span>
          </div>
            <mat-accordion *ngIf="!loading">
            
                <mat-expansion-panel  *ngFor="let course of coursesByIdSchoolApi">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <figure class="figure-svg-course">
                        <svg class="icon-svg-course">
                            <use xlink:href="assets/iconos/chalkboard-solid.svg#Layer_1"></use>
                        </svg>
                    </figure> {{ course.titulo }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="row">
                    <div class="col-12">
                      <p>{{ course.descripcion }}</p>
                    </div>
                    <div class="col-12">
                      <div *ngFor="let sesion of course.sesiones" class="sesion-container" (click)="openModal(sesion.id_sesion)">
                        <p>{{ sesion.titulo_sesion }}</p>
                        <button  class="button-sesion"><i class="fas fa-eye icon-eye"></i></button>
                      </div>
                    </div>
                    
                  </div>
                  
                </mat-expansion-panel>
      
              </mat-accordion>
        </div>
    </div>
</div>