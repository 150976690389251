<header>
    <app-header></app-header>
</header>
<div>
    <main class="row remove-margin-left remove-margin-right">
        <div class="col-md-12 col-lg-4 margin-bottom-sm">
            <div class="info-profile">
                <div class="avatar"
                style="background-image: url('../../../assets/img/avatarTest.png');">
                </div>
                <h1 class="text-align-center margin-top-xs">Tu nombre</h1>
                <div class="info-level">
                    <span class="display-flex margin-bottom-sm"><i class="fas fa-medal icon"></i><h5 class="semi-bold">Iniciado Developer</h5></span>
                    <span class="display-flex justify-items-center"><i class="fas fa-flag icon"></i><h5 class="semi-bold">4 Sesiones</h5></span>
                </div>
                <div class="info-biography">
                    <h4>Biografía</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec ornare ex. Aliquam imperdiet magna in venenatis pellentesque. Suspendisse ultrices faucibus gravida.</p>
                </div>
                <div class="social-media text-align-center">
                    <a href="https://en.pixeling.co/" target="_blank"><i class="fab fa-linkedin"></i></a>
                    <a href="https://www.instagram.com/pixeling.co/" target="_blank"><i class="fab fa-instagram-square"></i></a>
                    <a href="https://www.facebook.com/pixeling.co/?ref=bookmarks" target="_blank"><i class="fab fa-facebook-square"></i></a>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-lg-8">
            <div class="text-align-center">
                <h2>Historial de sesiones</h2>
                <div class="container-table">
                    <table mat-table [dataSource]="dataSource">

                        <ng-container matColumnDef="position">
                            <th mat-header-cell *matHeaderCellDef> No. </th>
                            <td mat-cell *matCellDef="let element"> {{element.position}} </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="sesion">
                            <th mat-header-cell *matHeaderCellDef> Sesión </th>
                            <td mat-cell *matCellDef="let element"> {{element.sesion}} </td>
                        </ng-container>

                        <ng-container matColumnDef="curso">
                            <th mat-header-cell *matHeaderCellDef> Curso </th>
                            <td mat-cell *matCellDef="let element"> {{element.curso}} </td>
                        </ng-container>

                        <ng-container matColumnDef="escuela">
                            <th mat-header-cell *matHeaderCellDef> Escuela </th>
                            <td mat-cell *matCellDef="let element"> {{element.escuela}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    </table>
                </div>
            </div>
        </div>
    </main>
</div>

