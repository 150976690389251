import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SesionsService } from '../../services/sesions.service';

@Component({
  selector: 'app-modal-sesion',
  templateUrl: './modal-sesion.component.html',
  styleUrls: ['./modal-sesion.component.css']
})
export class ModalSesionComponent implements OnInit {

  currentSesion: {};
  idSesion: string;
  private player;
  private ytEvent;
  loading = true;

  constructor(private sesionsService: SesionsService, public dialogRef: MatDialogRef<Component>, @Inject(MAT_DIALOG_DATA) data) {
    this.idSesion = data.idSesion;
    this.getSesion();
  }

  ngOnInit(): void {

  }

  getSesion(): any {
    this.sesionsService.getSesion(this.idSesion).subscribe(sesion => {
      this.currentSesion = sesion;
      this.loading = false;
    });
  }

  onStateChange(event): void {
    this.ytEvent = event.data;
  }
  savePlayer(player): void {
    this.player = player;
  }

}
