import { Component, OnInit } from '@angular/core';
import { SchoolsService } from '../../services/schools.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-schools-view',
  templateUrl: './schools-view.component.html',
  styleUrls: ['./schools-view.component.css']
})
export class SchoolsViewComponent implements OnInit {
  schools: [];

  constructor(private schoolsService: SchoolsService, private router: Router) { }

  ngOnInit(): void {

    this.listSchools();
  }

  listSchools(): any {
    this.schools = this.schoolsService.getSchools();
  }
  selectedCourse(idSchool: number): void{
    this.router.navigate(['/cursos', idSchool]);
  }

}
